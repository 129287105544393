<template>
  <div class="categories-wrapper page">
    <data-table
      :data="getData"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DataTable from '@/components/dataTable'

export default {
  name: 'Categories',
  components: {
    DataTable
  },
  computed: {
    ...mapGetters('categories', ['getData'])
  },
  async created () {
    await this.loadData()
  },
  methods: {
    ...mapActions('user', { loadDataUser: 'loadData' }),
    ...mapActions('settings', { loadDataSettings: 'loadData' }),
    ...mapActions('categories', { loadDataCategories: 'loadData' }),
    async loadData () {
      // TODO: Подумать над оптимизацией загрузки данных НА ВСЕХ стр.
      await this.$bus.emit('start-loader')
      await this.loadDataUser()
      await this.loadDataSettings()
      await this.loadDataCategories()
      await this.$nextTick()
      await this.$bus.emit('stop-loader')
    },
  }
}
</script>

<style lang="scss" src="./style.scss" />